import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../../../components/Layout';
import UniversalCard from '../../../components/molecules/UniversalCard';
import OrientationSection from '../../../components/molecules/OrientationSection';
import CourseSection from '../../../components/molecules/CoursesSection';
import CFS from '../../../components/molecules/CFS';
import ReviewsSection from '../../../components/molecules/ReviewsSection';
import Collapse from '../../../components/molecules/Collapse';
import Checkmark from '../../../components/atoms/Checkmark';
import OrientaionCard from '../../../components/atoms/OrientaionCard';
import {
  CURSO_ELE_PLUS_ROUTE,
  DESCARDA_EL_FOLLETO_ROUTE,
  INSCRIPCION_ROUTE,
  ORIENTACION_PROFESIONAL_ROUTE,
  DISCOUNT_SPANISH_LESSONS_ROUTE,
} from '../../../constants/routes';

import imgSecSection from '../../../assets/images/pages/courses/curso-fele-intensivo/s-section-img.png';
import imgGr from '../../../assets/images/pages/courses/curso-fele-intensivo/gr.svg';
import imgEpeea from '../../../assets/images/pages/courses/curso-fele-intensivo/epeea.svg';
import imgAm from '../../../assets/images/pages/courses/curso-fele-intensivo/am.svg';
import imgApyol from '../../../assets/images/pages/courses/curso-fele-intensivo/apyol.svg';
import imgReviewer from '../../../assets/images/pages/courses/curso-fele-intensivo/reviewer.jpeg';
import ImgQuotes from '../../../assets/images/favicon/quotes.inline.svg';
import ImgOri1 from '../../../assets/images/pages/courses/curso-fele-intensivo/rym.svg';
import ImgOri2 from '../../../assets/images/pages/courses/curso-fele-intensivo/op.svg';
import ImgOther1 from '../../../assets/images/pages/courses/curso-fele-intensivo/popular-c-1.png';
import ImgOther2 from '../../../assets/images/pages/courses/curso-fele-intensivo/popular-c-2.png';

import '../../../assets/styles/pages/curso-fele-intensivo.scss';

const week1 = `
<h5>Lunes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Bienvenida al curso</li>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Presentación</li>
    <li class='faq-list__item'>La papel del profesor ELE</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Martes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Aprender a planificar</li>
    <li class='faq-list__item'>Métodos comunicativos</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Miércoles</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>El papel del estudiante</li>
    <li class='faq-list__item'>Aprendizaje cognitivo de lenguas</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Jueves</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Gestión del aula</li>
    <li class='faq-list__item'>La evaluación y la corrección de errores</li>               
    <li class='faq-list__item'>Planificación de clases</li>                
</ul>
<h5>Viernes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Enseñar vocabulario</li>
    <li class='faq-list__item'>La creatividad en el aula</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
`;
const week2 = `
<h5>Lunes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Presentación del lenguaje</li>
    <li class='faq-list__item'>El contexto y las tareas</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Martes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Gramática cognitiva (I)</li>
    <li class='faq-list__item'>Ser y estar / Por y para</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Miércoles</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Las macro destrezas</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Jueves</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Gramática cognitiva (II)</li>
    <li class='faq-list__item'>Los pasados</li>               
    <li class='faq-list__item'>Planificación de clases</li>                
</ul>
<h5>Viernes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Aprender jugando</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
`;
const week3 = `
<h5>Lunes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Gramática cognitiva (III)</li>
    <li class='faq-list__item'>El contraste de los pasados</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Martes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>La pronunciación</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Miércoles</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Enseñar con materiales auténticos</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Jueves</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Gramática cognitiva (IIII)</li>
    <li class='faq-list__item'>El subjuntivo</li>               
    <li class='faq-list__item'>Planificación de clases</li>                
</ul>
<h5>Viernes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Enseñar Cultura</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
`;
const week4 = `
<h5>Lunes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Pronunciación</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Martes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Enseñar español de negocios - ENE</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Miércoles</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Orientación profesional</li>
    <li class='faq-list__item'>Planificación de clases</li>
</ul>
<h5>Jueves</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>MCER - Plan curricular Instituto Cervantes</li>             
    <li class='faq-list__item'>Planificación de clases</li>                
</ul>
<h5>Viernes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Clases prácticas, observación y feedback</li>
    <li class='faq-list__item'>Examen</li>
    <li class='faq-list__item'>Fin de curso</li>
</ul>
`;

const CursoFeleIntensivo = ({ data }) => {
  const allPrismicCourses = data?.allPrismicCourses.edges;

  return (
    <Layout
      title="Curso FELE - Formador de Español como Lengua Extranjera"
      description="Curso FELE: Formación innovadora, con grupos reducidos y a un precio inmejorable. Conviértete en profesor de español en tan sólo 1 mes. ¡Inscríbete ahora!"
    >
      <div className="curso-fele-intrnsivo">
        <CFS
          title="Curso FELE Intensivo"
          description="FELE: Formador de español como lengua extranjera. Consigue un título reconocido internacionalmente en 4 semanas"
          subsctribeBtn="Inscríbete ahora"
          schedule={{ table: '4 semanas', clock: '160 horas de formación' }}
        />
        <section className="second-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="second-section__title c-title-32">
                  Descubre una carrera gratificante
                </h2>
                <p className="second-section__description c-text-18 c-text-18--gray-100">
                  El curso FELE Intensivo de 160 horas se considera el curso más completo para ser
                  profesor de español y está reconocido por todo el mundo. Este curso te capacita
                  para encontrar trabajo aquí en Barcelona o donde te llevan tus viajes.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div className="left">
                  <img src={imgSecSection} alt="Happy person" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="right">
                  <h3 className="right__title">Este curso es perfecto para ti si:</h3>
                  <div className="wrapper">
                    <Checkmark>
                      Nunca has enseñado español o tienes muy poca experiencia formal.
                    </Checkmark>
                    <Checkmark>
                      Quieres ampliar tus oportunidades laborales con una formación de calidad.
                    </Checkmark>
                    <Checkmark>Eres no nativo con nivel C de español</Checkmark>
                    <Checkmark>Necesitas ayuda para saltar al extranjero.</Checkmark>
                    <Checkmark>
                      Te interesa la pedagogía y las estrategias de aprendizaje-enseñanza de lenguas
                      extranjeras.
                    </Checkmark>
                    <Checkmark>
                      Has estudiado filología, traducción, o alguna carrera relacionada con la
                      lengua y quieres adentrarte en la enseñanza ELE.
                    </Checkmark>
                    <Checkmark>
                      Quieres cambiar el rumbo de tu vida, aprender una nueva profesión y viajar
                      trabajando.
                    </Checkmark>
                  </div>
                </div>
              </div>
            </div>
            <div className="row extra-info-cards">
              <div className="col-lg-3 col-sm-6">
                <UniversalCard
                  title="Grupos reducidos"
                  description="Máximo de 8 aprendices de profesor por grupo que permite un trato personalizado"
                  img={imgGr}
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <UniversalCard
                  title="Experiencia práctica en el aula"
                  description="Ganarás confianza enseñando a grupos de estudiantes reales"
                  img={imgEpeea}
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <UniversalCard
                  title="Aulas modernas"
                  description="Pizarras interactivas, ordenadores, proyectores, biblioteca, ambiente profesional"
                  img={imgAm}
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <UniversalCard
                  title="Ayuda profesional y orientación laboral"
                  description="Únete a una gran red de contactos y ofertas de trabajo"
                  img={imgApyol}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="third-section c-section-includes c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgReviewer} alt="" className="img-main" />
                  </div>
                  <div className="block_l__text">
                    <ImgQuotes />
                    <h3 className="c-text-18">Hice la formación de profesores y fue genial</h3>
                    <p>
                      Prácticas con estudiantes reales desde el primer día, un montón de recursos y
                      materiales de todo tipo para preparar las clases y las sesiones teóricas están
                      muy enfocadas en lo realmente práctico para el día a día del profesor
                      <div className="line"></div>
                      <strong>Pablo Dominguez Antuña</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">¿Qué incluye?</h2>
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <Checkmark>
                        Formación presencial de <strong>160 horas.</strong>
                      </Checkmark>
                    </div>
                    <div className="benefit">
                      <Checkmark>Tasas de certificación.</Checkmark>
                    </div>
                    <div className="benefit">
                      <Checkmark>Tutores expertos y profesionales.</Checkmark>
                    </div>
                    <div className="benefit">
                      <Checkmark>
                        Acceso a nuestra hermosa biblioteca, wifi de alta velocidad y ordenadores
                        Mac.
                      </Checkmark>{' '}
                    </div>
                    <div className="benefit">
                      <Checkmark>
                        <strong>40 horas</strong> de prácticas evaluadas con estudiantes reales.
                      </Checkmark>
                    </div>
                    <div className="benefit">
                      <Checkmark>
                        Contactos profesionales y orientación laboral – nuestra escuela contrata a
                        nuestros graduados y les mantiene informados de ofertas y formación
                        contínua.
                      </Checkmark>
                    </div>
                    <div className="benefit">
                      <Checkmark>Un acompañamiento integral.</Checkmark>
                    </div>
                    <div className="benefit">
                      <Checkmark>Desayuno pre curso.</Checkmark>
                    </div>
                  </div>
                  <div className="block_r__price block_r__price--full">
                    <h3 className="c-text-13">PRECIO COMPLETO:</h3>
                    <h3 className="c-title-32">1650€</h3>
                  </div>
                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">PRECIO CON DESCUENTO:</h3>
                    <h3 className="c-title-32">1500€</h3>
                    <span className="notion">Si pagas íntegramente un mes antes de tu curso</span>
                  </div>
                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">ELE+ Precio General:</h3>
                    <h3 className="c-title-32">800€</h3>
                  </div>
                  <div className="block_r__btns">
                    <Link href={INSCRIPCION_ROUTE} className="c-btn c-btn--green">
                      Inscríbete ahora
                    </Link>
                    <Link to={DESCARDA_EL_FOLLETO_ROUTE} className="c-btn c-btn--white">
                      Descarga el folleto
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="fourth-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col">
                <CourseSection title="Próximos cursos" cardData={allPrismicCourses} />
              </div>
            </div>
          </div>
        </div>

        <OrientationSection title="Apoyo integral al estudiante" description="Conoce cómo:">
          <div className="row">
            <div className="col-lg-6">
              <OrientaionCard
                btn={{ to: ORIENTACION_PROFESIONAL_ROUTE, text: 'Leer más' }}
                imgUrl={ImgOri1}
                title="Apoyo al nuevo profesor"
              />
            </div>
            <div className="col-lg-6">
              <OrientaionCard
                btn={{ to: ORIENTACION_PROFESIONAL_ROUTE, text: 'Leer más' }}
                imgUrl={ImgOri2}
                title="Orientación profesional"
              />
            </div>
          </div>
        </OrientationSection>

        <section className="seventh-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="seventh-section__title c-title-34">
                  El proceso de inscripción de ELE Barcelona
                </h2>
                <div className="stepper">
                  <div className="stepper__item">
                    <div className="item__counter">1</div>
                    <div className="item__title">Inscripción</div>
                    <div className="item__description">Completa el formulario de solicitud</div>
                  </div>

                  <div className="stepper__item">
                    <div className="item__counter">2</div>
                    <div className="item__title">Tarea de conocimiento previo</div>
                    <div className="item__description">
                      Una mini prueba para evaluar tu conciencia del idioma
                    </div>
                  </div>

                  <div className="stepper__item">
                    <div className="item__counter">3</div>
                    <div className="item__title">Entrevista personal</div>
                    <div className="item__description">
                      Una vez aprobada la tarea, harás una entrevista con nuestra coordinadora.
                      Hablarás del curso, tus necesidades, objetivos y cualquier otra pregunta que
                      tengas.
                    </div>
                  </div>

                  <div className="stepper__item">
                    <div className="item__counter">4</div>
                    <div className="item__title">Pago</div>
                    <div className="item__description">
                      Confirmamos tu plaza y pagas la tasa del curso.
                    </div>
                  </div>

                  <div className="stepper__item">
                    <div className="item__counter">5</div>
                    <div className="item__title">¡Empieza tu formación!</div>
                    <div className="item__description">
                      Te damos acceso al material de estudio pre curso y comienzas tu formación en
                      la escuela.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cta-block row">
              <div className="col">
                <div className="cta">
                  <div className="left">
                    <h2 className="cta__title">¿Preparado/a para empezar tu aventura docente?</h2>
                  </div>
                  <div className="right">
                    <Link to={INSCRIPCION_ROUTE} className="c-btn c-btn--green">
                      Inscríbete hoy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="eigth-section  c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <h3 className="c-title-34">Programa y descripción del curso</h3>
                <p className="c-text-18">
                  El curso FELE intensivo cuenta con 160 horas de formación presencial y a tiempo
                  completo durante 4 semanas.{' '}
                  <span className="text-fw-600">
                    El horario es de lunes a viernes, de 10.00 a 18.00
                  </span>
                </p>

                <div className="assesment-block">
                  <h4 className="c-title-20">¿El español no es tu lengua materna?</h4>
                  <p className="c-text-16">
                    Necesitas demostrar el nivel C1 para poder participar en el curso
                  </p>
                  <a
                    href="https://www.tryinteract.com/share/quiz/5ef381a48733d50014f9d82c"
                    className="c-btn c-btn--green"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Haz la prueba de nivel
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <Collapse title="Semana 1" description={week1} />
                <Collapse title="Semana 2" description={week2} />
                <Collapse title="Semana 3" description={week3} />
                <Collapse title="Semana 4" description={week4} />
                <Link to={DESCARDA_EL_FOLLETO_ROUTE} className="mt-50 c-btn c-btn--green">
                  Descargar el programa teórico
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="nineth-section">
          <ReviewsSection title={'Lo que los estudiantes dicen de ELE Barcelona'} />
        </section>

        <div className="c-faqs-section c-section-py-80">
          <div className="container ">
            <div className="row">
              <div className="col">
                <h3 className="c-faqs-section__title c-title-34">Preguntas frecuentes</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12">
                <Collapse
                  title="¿A quién enseño?"
                  description="Los estudiantes de ELE Barcelona son personas interesadas en el aprendizaje del español y que viven en Barcelona. En las clases prácticas intentamos que haya una gran variedad de nacionalidades para que conozcas las particularidades de cada estudiante. Tenemos estudiantes de Estados Unidos, Inglaterra, Rusia, Francia, Alemania, Holanda y Suiza. También de otros países de habla inglesa como Australia, Nueva Zelanda o Canadá. Estudiantes asiáticos de China, Japón y Corea. Vas a aprender a enseñar español a estudiantes de, al menos, 2 niveles: A1 (inicial) y B1 (intermedio), o bien A2 (pre intermedio) y B2 (avanzado)."
                />
                <Collapse
                  title="¿Por cuánto tiempo enseño?"
                  description="Tu primera lección es el primer día de curso y se trata de una actividad de 20 minutos para romper el hielo con el grupo. A partir de ahí, aumentarás el tiempo de la lección a media hora, progresando eventualmente a 40 minutos, 60 minutos para llegar a dos horas completas de clase completa en la última semana del curso."
                />
                <Collapse
                  title="¿Enseño todos los días?"
                  description="En promedio enseñas cada dos días, aunque es posible enseñar días consecutivos. Depende del número de aprendices de profesor que haya en tu grupo."
                />
                <Collapse
                  title="¿Observo a otros profesores?"
                  description="Observarás cada día a tus compañeros y, al menos, a dos profesores experimentados durante el curso. Además, toda la práctica docente se llevará a cabo en presencia de otros profesores en formación, por lo que tendrás constantemente retroalimentación al ser observado y observar constructivamente a tus compañeros cuando enseñan. Así te animamos a evaluar tu propia enseñanza y la de tus colegas. Al final de cada sesión tendrás un encuentro feedback de media hora con tu tutor para hablar de los puntos fuertes y débiles de la práctica de ese día. En ELE Barcelona consideramos que la observación de profesores es uno de los pilares fundamentales de una buena formación profesional."
                />
              </div>
              <div className="col-lg-6 col-12">
                <Collapse
                  title="¿Cómo me evalúan?"
                  description="La evaluación es continua durante todo el curso. Evaluamos: las clases que das, la planificación diaria de clase solo y con tu grupo, la creación y/o adaptación de materiales propuestos, la actitud positiva, la asistencia a clase, la empatía con tus estudiantes. Recibirás por escrito una evaluación diaria de tu clase práctica por medio de un informe detallado que el tutor realiza para evaluar las competencias que deberías tener en el aula. Asimismo, serás evaluado profesionalmente por tus compañeros a través de un cuaderno evaluativo semanal. En los trabajos escritos y creativos, también recibirás feedback profesional. Asimismo, tendrás la oportunidad de hacer una tutoría a mitad de curso para evaluar tu progreso. Finalmente, recibirás una evaluación durante la presentación de tu trabajo final de curso."
                />
                <Collapse
                  title="¿Qué calificación obtengo?"
                  description={`Tras completar con éxito todas las tareas y responsabilidades, la calificación se publicará dos semanas después de finalizado el curso. Puedes obtener A, B, aprobado o suspenso. La mayoría de los candidatos se les otorga una 'B' y excepcionalmente a los buenos maestros se les otorga una "A". Muy pocos candidatos suspenden debido a que los tutores te avisarán si tu trabajo durante el curso está por debajo del estándar. De igual manera, te aconsejarán sobre las áreas en las que necesites mejorar y te orientarán sobre cómo se pueden hacer mejoras.`}
                />
                <Collapse
                  title="¿Cómo es un típico día de curso?"
                  description={`<div>10:00-10:30 - Preparar el aula para recibir a los estudiantes del grupo de prácticas y organizar el material que vamos a trabajar.</div><div>10:30-12:30 - Clase práctica</div><div>12:30-13:00 - Feedback sobre la práctica</div><div>13:00-14:00 - Pausa comida</div><div>14:00-16:00 - Clase teórica y talleres </div><div>16.00-18.00 - Planificación de las clases de los días siguientes</div>`}
                />
              </div>
            </div>
          </div>
        </div>

        <section className="eleventh-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col">
                <h3 className="eleventh-section__title c-title-34">Otros cursos populares</h3>
                <p className="eleventh-section__description c-text-16 c-text-16--gray-100">
                  Ve más allá con ELE Barcelona
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Link to={CURSO_ELE_PLUS_ROUTE} className="other-course-card">
                  <div className="picture">
                    <img src={ImgOther1} alt="other-course-card" />
                  </div>
                  <div className="bottom">
                    <p className="bottom__description">
                      Curso ELE+ de una semana para los que ya son profesores
                    </p>
                    <p className="bottom__pricing">800€</p>
                  </div>
                </Link>
              </div>
              <div className="col-md-6">
                <Link to={DISCOUNT_SPANISH_LESSONS_ROUTE} className="other-course-card">
                  <div className="picture">
                    <img src={ImgOther2} alt="other-course-card" />
                  </div>
                  <div className="bottom">
                    <p className="bottom__description">Discount Spanish Lessons</p>
                    <p className="bottom__pricing">Desde 70€/mes</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: {
        data: { show_on_these_pages: { elemMatch: { page: { eq: "curso-fele-intensivo" } } } }
      }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY", locale: "es")
            to(formatString: "D MMMM YYYY", locale: "es")
            price
            is_price_per_month
          }
        }
      }
    }
  }
`;

export default CursoFeleIntensivo;
